import { Token } from '@/core/common-types/CommonTypes';
import { Module } from 'vuex';
import { Preferences } from '@capacitor/preferences';
import jwtDecode from "jwt-decode";
import { User } from '../store';
import { apolloClient } from '@/core/apollo/apolloClient';
import { faro, EventAttributes } from '@grafana/faro-web-sdk';

const tokenPattern = /^\s*JWT\s*[a-zA-Z0-9_-]+\.[a-zA-Z0-9_-]+\.[a-zA-Z0-9_-]+\s*$/;

type DecodedToken = {
	iat: number;
	email: string;
	userID: string;
	name: {
		first: string;
		last: string;
		full: string;
	},
	institutions: string[];
	roles: string[];
}


export type State = {
	authInitialized: boolean;
	token: Token;
	decodedToken: DecodedToken;
	user: User;
}

const tokenStorage = {
	async get(): Promise<string> {
		const item = await Preferences.get({ key: 'token' });

		if (!item.value) return '';
		if (isValidToken(item.value)) return item.value;

		return '';
	},
	async set(token: string) {
		return Preferences.set({
			key: 'token',
			value: token
		});
	},
	async remove() {
		return Preferences.remove({ key: 'token' });
	}
};

function isValidToken(value: string): value is string {
	return typeof value === 'string' && tokenPattern.test(value);
}

function decodeToken(value: string): DecodedToken {
	if (!isValidToken(value)) return emptyTokenData();

	try {
		return jwtDecode(value);
	} catch(error) {
		console.error('The given token could not be decoded', error);

		return emptyTokenData();
	}
}

function emptyTokenData(): DecodedToken {
	return {
		iat: 0,
		email: '',
		userID: '',
		name: {
			first: '',
			last: '',
			full: '',
		},
		institutions: [],
		roles: [],
	};

}

function emptyUser(): User {
	return {
		email: '',
		userID: '',
		firstName: '',
		lastName: '',
		institutions: [],
		roles: [],
		isDev: false,
		isOwner: false,
		isInstructor: false,
		isStudent: false,
		isParent: false,
		isAdmin: false,
		birthDate: null,
		correctiveLensesRequired: false,
		courses: [],
		permitIssued: null,
		permitIssuingState: null,
		permitNumber: null,
		students: []
	};
}

export const auth: Module<State, State> = {
	state() {
		return {
			authInitialized: false,
			token: '',
			decodedToken: emptyTokenData(),
			user: emptyUser(),
		};
	},
	getters: {
		authInitialized(state) {
			return state.authInitialized;
		},
		isAuthenticated(state) {
			return !!state.token;
		},
		token(state) {
			return state.token;
		},
		tokenIAT(state) {
			return state.decodedToken.iat;
		},
		user(state) {
			// state.user.institutions = ['mjm-hill-driving-school'];
			return state.user;
		},
	},
	actions: {
		async initializeAuth({ commit, dispatch, state, getters }) {
			if (state.authInitialized) return;

			const token = await tokenStorage.get();
			const decodedToken = decodeToken(token);
			commit('setToken', token);
			commit('setDecodedToken', decodedToken);
			commit('setUser', decodedToken);

			if (getters.enableFaro) {
				const session = faro.api.getSession();

				session.attributes['meta.userID'] = decodedToken.userID;
				session.attributes['meta.email'] = decodedToken.email;
				session.attributes['meta.name'] = decodedToken.name.first + ' ' + decodedToken.name.last;
				session.attributes['meta.institution'] = decodedToken.institutions[0];
				session.attributes['meta.roles'] = decodedToken.roles.sort().join(' ');

				faro.api.setSession(session);
			}

			dispatch('initializeInstructor', state.user);

			commit('initializeAuth');
		},
		async logOut({ dispatch, commit }) {
			await apolloClient.clearStore();
			commit('clearAuth');
			await tokenStorage.remove();
			dispatch('checkCustomHostname');
		},
		async logIn({ commit, state, dispatch, getters }, token) {
			await Preferences.clear();

			const decodedToken = decodeToken(token);

			commit('setToken', token);
			commit('setDecodedToken', decodedToken);
			commit('setUser', decodedToken);

			if (getters.enableFaro) {
				const session = faro.api.getSession();

				session.attributes['userID'] = decodedToken.userID;
				session.attributes['email'] = decodedToken.email;
				session.attributes['name'] = decodedToken.name.first + ' ' + decodedToken.name.last;
				session.attributes['institution'] = decodedToken.institutions[0];
				session.attributes['roles'] = decodedToken.roles.sort().join(' ');

				faro.api.setSession(session);
			}

			dispatch('initializeInstructor', state.user);

			await tokenStorage.set(token);
		},
	},
	mutations: {
		initializeAuth(state) {
			state.authInitialized = true;
		},
		clearAuth(state) {
			state.token = '';
			state.decodedToken = emptyTokenData();
			state.user = emptyUser();
		},
		setToken(state, token) {
			state.token = token;
		},
		setDecodedToken(state, decodedToken) {
			state.decodedToken = decodedToken;
		},
		setUser(state, decodedToken) {
			state.user = {
				email: decodedToken.email,
				userID: decodedToken.userID,
				firstName: decodedToken.name.first,
				institutions: decodedToken.institutions,
				lastName: decodedToken.name.last,
				roles: decodedToken.roles,
				isDev: decodedToken.roles.some((role) => role === 'dev'),
				isOwner: decodedToken.roles.some((role) => role === 'owner'),
				isInstructor: decodedToken.roles.some((role) => role === 'instructor'),
				isStudent: decodedToken.roles.some((role) => role === 'student'),
				isParent: decodedToken.roles.some((role) => role === 'parent'),
				isAdmin: decodedToken.roles.some((role) => role === 'administrator'),
			};
		},
	}
}