import { GUID, Token, UserCredentials } from "@/core/common-types/CommonTypes";
import Environment from "@/core/config/env.config";
import axios, { AxiosInstance } from 'axios';
import gql from 'graphql-tag';
import omitDeep from 'omit-deep-lodash';
import { apolloClient } from '../apollo/apolloClient';
import { FutureDrivingData, store, User } from "../store/store";
import { InvalidCredentialsException } from "./InvalidCredentialsException";
import { CLASSROOM_USER_PROGRESS, LESSON_WITH_QUESTIONS_QUERY, SUBMIT_PROGRESS_MUTATION, SET_ELEARNING_START_DATE_MUTATION, EXTEND_ELEARNING, DAILY_ELEARNING_LIMIT_QUERY } from "./classroomApi"
import { TRIGGER_EMAIL_MUTATION } from "./communicationApi";
import { LOG_MOBILE_ACTIVITY } from "./logApi"
import { INSTRUCTOR_QUERY } from "./schedulerApi";
import { faro } from '@grafana/faro-web-sdk';

export class DailyLimitMetError extends Error {
	constructor(...opts) {
		super(...opts);
		this.name = "DailyLimitMetError";
	}
}

export type ProgressItemInput = {
	type: string;
	lessonID: GUID;
	itemProgressed: GUID;
	timeSpent: number;
	details: QuestionAnswerInput[];
}

export type QuestionAnswerInput = {
	question: GUID;
	chosenAnswerID: GUID;
}

export type EmailInfo = {
	institutionSlug: string,
	content: string[],
	recipients: string,
	sender: string,
	subject: string
}

export const UPDATE_USER_QUERY = gql`
	mutation registeredUserUpdate($selectedUserFields: SelectedUserFields!, $userID: ID!) {
		registeredUserUpdate(selectedUserFields: $selectedUserFields, userID: $userID)
	}
`;

export const ADD_CARD_MUTATION = gql`
	mutation addCardPaymentMethod($institution: NonEmptyString!, $paymentInfo: PaymentInfo!, $studentCourseID: NonEmptyString!, $userID: NonEmptyString!) {
		addCardPaymentMethod(institution: $institution, paymentInfo: $paymentInfo, studentCourseID: $studentCourseID, userID: $userID) {
			cardFirstName
			cardLastName
			cardAddress
			cardZip
			cardLastFour
			cardExpMonth
			cardExpYear
			cardBrand
			token
			recurringToken
		}
	}
`;

export const REMOVE_CARD_MUTATION = gql`
	mutation removeUserCard($institution: NonEmptyString!, $userID: NonEmptyString!, $studentCourseID: NonEmptyString!) {
		removeUserCard(institution: $institution, userID: $userID, studentCourseID: $studentCourseID)
	}
`;

export const MAKE_PAYMENT_WITH_CARD_ON_FILE_MUTATION = gql`
	mutation makeCreditCardPaymentWithCardOnFileAndReturnCreatedTransactions($institution: NonEmptyString!, $studentCourseID: NonEmptyString!, $userID: NonEmptyString!, $amount: PositiveFloat!, $addCorrespondingFee: CorrespondingFee) {
		makeCreditCardPaymentWithCardOnFileAndReturnCreatedTransactions(institution: $institution, studentCourseID: $studentCourseID, userID: $userID, amount: $amount, addCorrespondingFee: $addCorrespondingFee) {
			... on Credit {
				transactionMethodInfo {
					... on CardPaymentTransactionInfo {
						type
						cardLastFour
						cardBrand
						cardHolder
					}
					... on CreditTransactionInfo {
						type
					}
				}
			}
			amount
			surcharge {
				amount
				percent
				minSurcharge
			}
			totalAmount
			createdAt
			description
			rawDescription
			id
			studentCourseID
			success
			transactionPurpose
			modificationInfo {
				adjustedAt
				type
				originalAmount
				adjustment
			}
		}
	}
`;

export const MAKE_PAYMENT_WITH_NEW_CARD_MUTATION = gql`
	mutation makeCreditCardPaymentWithNewCardAndReturnCreatedTransactions($institution: NonEmptyString!, $studentCourseID: NonEmptyString!, $userID: NonEmptyString!, $amount: PositiveFloat!, $paymentInfo: PaymentInfo!, $addCorrespondingFee: CorrespondingFee) {
		makeCreditCardPaymentWithNewCardAndReturnCreatedTransactions(institution: $institution, studentCourseID: $studentCourseID, userID: $userID, amount: $amount, paymentInfo: $paymentInfo, addCorrespondingFee: $addCorrespondingFee) {
			... on Credit {
				transactionMethodInfo {
					... on CardPaymentTransactionInfo {
						type
						cardLastFour
						cardBrand
						cardHolder
					}
					... on CreditTransactionInfo {
						type
					}
				}
			}
			amount
			surcharge {
				amount
				percent
				minSurcharge
			}
			totalAmount
			createdAt
			description
			rawDescription
			id
			studentCourseID
			success
			transactionPurpose
			modificationInfo {
				adjustedAt
				type
				originalAmount
				adjustment
			}
		}
	}
`;


export class API {
	#axios: AxiosInstance;

	constructor() {
		this.#axios = axios.create({
			baseURL: `${Environment.baseURL}:${Environment.port}`,
		});
	}

	async logIn(credentials: UserCredentials): Promise<Token> {
		try {
			const response = await this.#axios.post('/v1/auth/login', credentials);

			return response.data.token;
		}
		catch (error: any) {
			if (this.#isInvalidCredentials(error)) {
				throw new InvalidCredentialsException();
			}

			throw error;
		}
	}

	#isInvalidCredentials = (error: any) => {
		const {
			data: {
				message = ''
			} = {}
		} = error.response || {};

		return /Invalid\s+login/.test(message) || /User\s+not\s+found/.test(message);
	}

	async getInstructors(institutionSlug: string) {
		const response = await apolloClient.query({
			fetchPolicy: 'no-cache',
			query: INSTRUCTOR_QUERY,
			variables: {
				institution: institutionSlug,
				roles: ['instructor']
			}
		});
		return response.data.users;
	}

	async triggerEmail(args: EmailInfo) {
		const response = await apolloClient.mutate({
			mutation: TRIGGER_EMAIL_MUTATION,
			variables: args,
			fetchPolicy: 'network-only'
		})

		return response.data.triggerEmail;
	}

	/**
	 *
	 * @param userID
	 * @returns role
	 */
	async getUserDetails(userID: GUID): Promise<User> | undefined {
		if(userID) {
			const response = await apolloClient.query({
				fetchPolicy: 'no-cache',
				query: gql`
					query user($userID: ID!) {
						user(userID: $userID) {
							birthDate
							userID
							email
							correctiveLensesRequired
							firstName
							lastName
							permitIssued
							permitIssuingState
							permitNumber
							roles {
								slug
								name
							}
							students {
								birthDate
								firstName
								lastName
								userID
								permitIssued
								permitIssuingState
								permitNumber
								institutions
								institution
								courses {
									onHold
									balanceDue
									studentCourseStatus
									course {
										categories {
											name
											slug
										}
										name
										courseID
										requiredDriveHours
										requiredObserveHours
										status
										instances {
											end
											start
										}
										eLearningDailyLimitMinutes
										eLearningMinDaysBeforeFinishing
									}
									dates {
										eLearningStart
										eLearningActivation
										eLearningCompletion
										classCompletion
										roadTestScheduled
									}
									payment {
										cardFirstName
										cardLastName
										cardLastFour
										cardExpMonth
										cardExpYear
										cardBrand
									}
									driveHoursCompleted
									observationHoursCompleted
									earliestDriversTestDate
									futureDrivingData {
										mandatoryGapSpans {
											start
											end
										}
									}
									studentCourseID
									classroomUserProgress {
										syllabus {
											id
											version
										}
									}
								}
								school {
									name
									locationID
								}
								zones {
									name
									id
								}
								notes {
									userApp {
										noteID
										studentCourseID
										userID
										note
										type
									}
									userAppLessons {
										noteID
										studentCourseID
										userID
										note
										type
									}
								}
							}
							institutions
							institution
							courses {
								onHold
								studentCourseStatus
								balanceDue
								course {
									name
									categories {
										name
										slug
									}
									courseID
									requiredDriveHours
									requiredObserveHours
									status
									instances {
										date
										end
										start
									}
									eLearningDailyLimitMinutes
									eLearningMinDaysBeforeFinishing
								}
								dates {
									eLearningStart
									eLearningActivation
									eLearningCompletion
									classCompletion
									roadTestScheduled
								}
								payment {
									cardFirstName
									cardLastName
									cardLastFour
									cardExpMonth
									cardExpYear
									cardBrand
								}
								driveHoursCompleted
								driveHoursOffset
								observationHoursOffset
								observationHoursCompleted
								earliestDriversTestDate
								futureDrivingData {
									mandatoryGapSpans {
										start
										end
									}
								}
								studentCourseID
								classroomUserProgress {
									syllabus {
										id
										version
									}
								}
							}
							school {
								name
								locationID
							}
							zones {
								name
								id
							}
							notes {
								userApp {
									noteID
									studentCourseID
									userID
									note
									type
								}
								userAppLessons {
									noteID
									studentCourseID
									userID
									note
									type
								}
							}
						}
					}
				`,
				variables: {
					userID: userID
				}
			});

			return response.data.user;
		} else {
			return {
				birthDate: '',
				correctiveLensesRequired: false,
				courses: [],
				firstName: '',
				lastName: '',
				permitIssued: null,
				permitIssuingState: '',
				permitNumber: '',
				roles: [],
				students: [],
				userID: ''
			};
		}
	}


	/**
	 *
	 * @param userID
	 * @returns role
	 */
	async getUserValidationDetails(userID: GUID): Promise<User> {
		const response = await apolloClient.query({
			fetchPolicy: 'no-cache',
			query: gql`
				query user($userID: ID!) {
					user(userID: $userID) {
						permitIssued
						permitIssuingState
						permitNumber
						courses {
							onHold
							studentCourseStatus
							course {
								categories {
									slug
								}
							}
							unitID
							studentCourseID
						}
					}
				}
			`,
			variables: {
				userID: userID
			}
		});

		return response.data.user;
	}

	async getMandatoryGapSpans(institution: String, studentCourseID: String): Promise<FutureDrivingData> {
		const response = await apolloClient.query({
			query: gql`
				query futureDrivingData($institution: ID!, $studentCourseID: ID!) {
					futureDrivingData(institution: $institution, studentCourseID: $studentCourseID) {
						mandatoryGapSpans {
							start
							end
						}
					}
				}
			`,
			fetchPolicy: 'no-cache',
			variables: {
				institution,
				studentCourseID
			},
		});
		return omitDeep(response.data.futureDrivingData, '__typename') as FutureDrivingData;
	}

	subscribeToInstitutionSettings(institution: String) {
		return apolloClient.subscribe({
			query: gql`
				subscription institutionSettingsUpdated($institution: NonEmptyString!) {
					institutionSettingsUpdated(institution: $institution)
				}
			`,
			variables: {
				institution
			}
		});
	}

	async getInstitutionSettings(slug: GUID) {
		const response = await apolloClient.query({
			query: gql`
				query institutionSettings($slug: ID!) {
					institutionSettings(slug: $slug) {
						logo
						moduleSettings {
							eLearning
							classroom
							schedulerAccess
							registration
							instructorApp
							customBranding
						}
						notifications {
							endDate
							message
							startDate
							title
							type
							showOnHome
							showOnLessons
							showOnScheduler
							showOnNotificationsTab
						}
						paymentSettings {
							surcharge {
								enabled
								minAmount
								percent
							}
						}
						schedulerSettings {
							lockFutureAppointments {
								ifBalanceDue
								maxDrivesAllowedWhenBalanceDue
								ifAwaitingLearningCompletion
								maxDrivesAllowedBeforeCompletion
								ifAwaitingRoadTestScheduling
								maxDrivesAllowedBeforeRoadTestScheduling
							}
							schedulingOverrides {
								enabled
								maxDrivesAllowed
								maxObservesAllowed
							}
							mandatoryGap
							calculateMandatoryGap
							calculateMandatoryGapOffsetDays
							minimumCancellationTime
							cancellationFee
							hideInstructors
							sessionSelection {
								granularSessionSelection
								sessionReservationType
								frontLoadRegistration
							}
							timeSlotTitle
						}
						registrationInstitutionConfig {
							payment {
								mode
								gateway
								payTrakSDKVersion
								testApiKey
								liveApiKey
								locationID
								allowCreditCardPayment
							}
						}
						timeZone
						phone
						email
						name
						slug
						trial
						stateSetting {
							schedulerSettings {
								observationDisabled
								licenseEligibility {
									years
									months
								}
							}
							state
						}
					}
				}
			`,
			variables: {
				slug: slug
			},
			fetchPolicy: 'no-cache'
		});

		return omitDeep(response.data.institutionSettings, '__typename');
	}

	async getStateSettings() {
		const response = await apolloClient.query({
			query: gql`
				query stateSettings {
					stateSettings {
						state
						schedulerSettings {
							observationDisabled
							licenseEligibility {
								years
								months
							}
							permitEligibility {
								years
								months
							}
						}
					}
				}
			`,
			fetchPolicy: 'no-cache'
		});
		return omitDeep(response.data.stateSettings, '__typename');
	}

	async getNotifications(institution: String) {
		const response = await apolloClient.query({
			query: gql`
				query notifications($institution: ID!) {
					notifications(institution: $institution) {
						notificationID
						startDate
						endDate
						message
						title
						type
						showOnHome
						showOnLessons
						showOnScheduler
						showOnNotificationsTab
					}
				}
			`,
			variables: {
				institution: institution
			},
			fetchPolicy: 'no-cache'
		});

		return omitDeep(response.data.notifications, '__typename');
	}

	async getFeatureFlags() {
		const response = await apolloClient.query({
			query: gql`
				query featureFlags {
					featureFlags {
						inAppBilling
						appVersions {
							android {
								current
								min
							}
							iOS {
								current
								min
							}
						}
						logRocketSettings {
							maximumLiteLesson
						}
					}
				}
			`,
			fetchPolicy: 'no-cache'
		});
		return omitDeep(response.data.featureFlags, '__typename');
	}

	async getBrowserSupport() {
		const response = await apolloClient.query({
			query: gql`
				query browserSupport {
					browserSupport {
						minimumSupportedMethods {
							constructorType
							name
						}
					}
				}
			`,
			fetchPolicy: 'no-cache'
		});
		return omitDeep(response.data.browserSupport, '__typename');
	}

	async getUserTransactions(userID: GUID, institution: String) {
		const response = await apolloClient.query({
			query: gql`
				query userTransactions(
					$institution: NonEmptyString!,
					$userID: UUID!
				) {
					userTransactions(
						institution: $institution,
						userID: $userID
					) {
						... on Credit {
							transactionMethodInfo {
								... on CardPaymentTransactionInfo {
									type
									cardLastFour
									cardBrand
									cardHolder
								}
								... on CreditTransactionInfo {
									type
								}
							}
						}
						amount
						surcharge {
							amount
							percent
							minSurcharge
						}
						totalAmount
						createdAt
						description
						rawDescription
						id
						studentCourseID
						success
						transactionPurpose
						modificationInfo {
							adjustedAt
							type
							originalAmount
							adjustment
						}
					}
				}
			`,
			variables: {
				institution,
				userID,
			},
			fetchPolicy: 'no-cache'
		});

		return response.data.userTransactions.sort((a, b) => {
			return a.createdAt - b.createdAt;
		});
	}

	async getClassroomUserProgress(studentCourseID: GUID) {
		const response = await apolloClient.query({
			query: CLASSROOM_USER_PROGRESS,
			variables: {
				studentCourseID: studentCourseID
			},
			fetchPolicy: 'no-cache'
		})

		return response.data.classroomUserProgress;
	}

	async getLessonWithQuestions(lessonID: string, syllabusID: string, attemptNum: number) {
		const response = await apolloClient.query({
			query: LESSON_WITH_QUESTIONS_QUERY,
			variables: {
				lessonID,
				syllabus: syllabusID,
				attemptNum
			},
			fetchPolicy: 'no-cache'
		})

		return response.data.lessonWithQuestions;
	}

	async submitClassroomProgressions(studentCourseID: string, progressions: Array<ProgressItemInput>) {
		const response = await apolloClient.mutate({
			mutation: SUBMIT_PROGRESS_MUTATION,
			variables: {
				studentCourseID,
				progressions
			},
			fetchPolicy: 'network-only'
		})
		.then(({data}) => data?.submitClassroomProgressions)
		.catch((error) => {
			const errorMessageDailyLimitAsNumber = Number(error.message);
			if (!isNaN(errorMessageDailyLimitAsNumber)) {
				return new DailyLimitMetError(errorMessageDailyLimitAsNumber);
			}
		});

		return response;
	}

	async setELearningStartDate(studentCourseID: string) {
		const response = await apolloClient.mutate({
			mutation: SET_ELEARNING_START_DATE_MUTATION,
			variables: {
				studentCourseID
			},
			fetchPolicy: 'network-only'
		})

		return response.data.setELearningStartDate;
	}

	async getDailyELearningLimit(userID: string) {
		if (!userID) {
			return 0;
		}
		const response = await apolloClient.query({
			query: DAILY_ELEARNING_LIMIT_QUERY,
			variables: {
				userID
			},
			fetchPolicy: 'no-cache'
		});

		return response?.data?.getDailyELearningLimit;
	}

	async logMobileActivity(mobileLog: any) {
		const deviceData = store.getters.getAdditionalLogData
		mobileLog.additionalData = {
			...deviceData,
			...mobileLog.additionalData
		}

		if (['ios-ionic', 'android-ionic'].includes(Environment.platform)) {
			mobileLog.additionalData.isMobile = true;
			mobileLog.additionalData.operatingSystem = Environment.platform;
		}

		if (store.getters.enableFaro) {
			const flatten = (object, prefix = '') => {
				return Object.keys(object).reduce((prev, element) => {
					return object[element]  && typeof object[element] == 'object' &&  !Array.isArray(element)
					? { ...prev, ...flatten(object[element], `${prefix}${element}.`) }
					: { ...prev, ...{ [`${prefix}${element}`]: String(object[element]) } }
				}, {})
			}
			const flatLog = flatten(mobileLog);
			if (mobileLog.additionalData?.status === 'Failure') {
				faro.api.pushError(new Error(mobileLog.event || 'Unknown'), {
					context: flatLog
				})
			} else {
				faro.api.pushEvent('activity', flatLog);
			}
		}

		const response = await apolloClient.mutate ({
			mutation: LOG_MOBILE_ACTIVITY,
			variables: {
				mobileLog
			},
			fetchPolicy: 'no-cache'
		})
	}

	async extendELearning(studentCourseID: string, transactionID: number) {
		const response = await apolloClient.mutate({
			mutation: EXTEND_ELEARNING,
			variables: {
				studentCourseID,
				transactionID
			},
			fetchPolicy: 'network-only'
		})

		return response.data.extendELearning;
	}
}
export const api = new API();