import { Module } from 'vuex';
import { Preferences } from '@capacitor/preferences';
import { api } from '../../api/api';

export type InstructorInfo = {
	userID: string;
	firstName: string;
	lastName: string;
};

export type State = {
	activeSession: {
		instanceID: string;
		instanceDate: number | null;
		sessionID: string;
		timeSlotID: string;
		driverStudentCourseID: string;
		driverUserID: string
	}
	activeInstructor: InstructorInfo | null;
	instructors: any;
	sessionState: string;
	instructorHomeTimeSlots: any;
	schedulerStudents: any;
	skillSettings: {
		allowMultipleAttempts: boolean;
		allowPositiveNegativeAttempts: boolean;
		allowSkillCompletion: boolean;
		allowSkillRatings: boolean;
		showSkillsBySkillSet: boolean;
		skillRatingScale: number;
		trackAttemptedBySkillSet: boolean; // else use date
	}
}

export const instructor: Module<State, State> = {
	state() {
		return {
			activeSession: {
				// timeSlotID used for top-level data state
				timeSlotID: '',
				instanceID: '',
				instanceDate: null,
				sessionID: '',
				driverStudentCourseID: '',
				driverUserID: ''
			},
			activeInstructor: null,
			instructors: null,
			sessionState: 'default',
			instructorHomeTimeSlots: null,
			schedulerStudents: [],
			skillSettings: {
				allowMultipleAttempts: false,
				allowPositiveNegativeAttempts: false,
				allowSkillCompletion: false,
				allowSkillRatings: false,
				showSkillsBySkillSet: true,
				skillRatingScale: 7,
				trackAttemptedBySkillSet: false
			}
		};
	},
	actions: {
		setActiveSession({ commit }, data) {
			commit('setActiveSession', {
				timeSlotID: data.timeSlotID,
				instanceID: data.instanceID,
				instanceDate: data.instanceDate,
				sessionID: data.sessionID,
				driverStudentCourseID: data.driverStudentCourseID,
				driverUserID: data.driverUserID
			});
		},
		setSessionState({ commit }, data) {
			commit('setSessionState', data);
		},
		clearActiveSession({ commit }) {
			commit('clearActiveSession');
		},
		async setInstructors({ commit }, user) {
			const instructorsResults = await api.getInstructors(user.institutions?.[0]);
				instructorsResults.sort((a: any, b: any) => {
					const aFirstLast = `${a.firstName} ${a.lastName}`;
					const bFirstLast = `${b.firstName} ${b.lastName}`;
					if (aFirstLast < bFirstLast) return -1;
					if (aFirstLast > bFirstLast) return 1;
					return 0;
				});

			commit('setInstructors', instructorsResults);
		},
		async initializeInstructor({ commit, dispatch, state }, user) {
			if (user?.isInstructor) {
				commit('setActiveInstructor', {
					userID: user.userID,
					firstName: user.firstName,
					lastName: user.lastName
				});
			}
			if (['administrator', 'owner'].some((role) => user?.roles?.includes(role))) {
				await dispatch('setInstructors', user);
			}
			if (!user?.isInstructor) {
				commit('setActiveInstructor', state.instructors?.[0] || null);
			}
		},
	},
	mutations: {
		setActiveSession(state, {instanceID, sessionID, driverStudentCourseID, driverUserID, timeSlotID, instanceDate}) {
			// Set the value in preferences as a backup.
			Preferences.set({
				key: 'activeSession',
				value: JSON.stringify(
					{
						timeSlotID,
						instanceID: instanceID,
						instanceDate,
						sessionID: sessionID,
						driverStudentCourseID,
						driverUserID,
					}
				)
			});

			// Set the value in the store for access for Apollo queries.
			state.activeSession.timeSlotID = timeSlotID;
			state.activeSession.instanceID = instanceID;
			state.activeSession.instanceDate = instanceDate;
			state.activeSession.sessionID = sessionID;
			state.activeSession.driverStudentCourseID = driverStudentCourseID;
			state.activeSession.driverUserID = driverUserID;
		},
		setActiveInstructor(state, data) {
			state.activeInstructor = data;
		},
		setInstructors(state, data) {
			state.instructors = data;
		},
		setSchedulerStudents(state, data) {
			state.schedulerStudents = data;
		},
		setSessionState(state, sessionState) {

			// Set the value in preferences.
			Preferences.set({
				key: 'sessionState',
				value: sessionState
			});

			// Set the current state.
			state.sessionState = sessionState;
		},
		clearActiveSession(state) {
			Preferences.remove({ key: 'activeSession' });
			Preferences.remove({ key: 'sessionState' });
			Preferences.keys().then(results => {
				const skillProgressKeys = results?.keys.filter(key => key.includes('SkillProgress:{\"id\"'));
				skillProgressKeys.forEach(key => {
					Preferences.remove({ key: key });
				})
			});
			// TODO: replace query using timeSlotID so we can also remove it from the store
			state.activeSession.instanceID = '';
			state.activeSession.sessionID = '';
			state.activeSession.driverStudentCourseID = '';
			state.activeSession.driverUserID = '';
			state.sessionState = 'default';
		},
		setInstructorHomeTimeSlots(state, data) {
			state.instructorHomeTimeSlots = data;
		},
	},
	getters: {
		activeSession(state) {
			return state.activeSession;
		},
		sessionState(state) {
			return state.sessionState;
		},
		instructorHomeTimeSlots(state) {
			return state.instructorHomeTimeSlots;
		},
		activeInstructor(state) {
			return state.activeInstructor;
		},
		instructors(state) {
			return state.instructors;
		},
		schedulerStudents(state) {
			return state.schedulerStudents;
		},
		skillSettings(state) {
			return state.skillSettings
		}
	}
}